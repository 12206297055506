import { website } from '@/axios'
export const categoriesLink = 'admin/product-category'

export default {
  getDetails (id) {
    return website().get(`${categoriesLink}/${id}`)
  },
  add (payload) {
    return website().post(categoriesLink, payload)
  },
  update (id, payload) {
    return website().patch(`${categoriesLink}/${id}`, payload)
  },
  getAll () {
    return website().get(`${categoriesLink}`)
  }
}
